import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Vuex from './store/store.js';
import vue3Lottie from 'vue3-lottie';

const app = createApp(App);
app.config.globalProperties.$filters = {
    formatNumber(number) {
        return Intl.NumberFormat().format(number);
    },
};
app.use(router).use(Vuex).use(vue3Lottie).mount('#app');
