<template>
    <div class="wrap">
        <div class="lottie">
            <Vue3Lottie :animationData="animationJson" :width="60" :height="60" />
        </div>
        <h1>두 사람의 사주를
            분석 중입니다</h1>
    </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import animationJson from '@/assets/againlove_lottie.json';
export default {
    components: {
        Vue3Lottie
    },
    data() {
        return {
            animationJson
        }
    },
}
</script>

<style scoped>
.wrap .lottie {
    padding-top: 30vh;
}

.wrap h1 {
    font-family: 'iceJaram', sans-serif;
    color: #fff;
    font-size: 36px;
    width: 60%;
    margin-top: 20px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.3;
    word-break: keep-all;
}
</style>