<template>
    <div class="wrap">
        <h2>🙏 재회가 가능할까요?</h2>
        <div class="search_box" id="search_box">
            <input id="search-input" type="text" placeholder="닉네임+내용 키워드 2자 이상 검색" @focus="FocusIn" @blur="FocusOut"
                @keyup.enter="GoSearch" />
            <button @click="GoSearch">
                <img src="@/assets/i_search.svg" alt="검색" />
            </button>
        </div>
        <div class="comment_list">
            <comment-template v-for="Data in CommentDataList" :key="Data" :CommentData="Data" @BtnShowView="OpenView"
                @getlistAgain="GoSearch">
            </comment-template>
        </div>
        <div class="bottom_fixed">
            <button class="write_comment" @click="openWrite">💬 투표 댓글 남기기</button>
        </div>
        <write-comment v-if="ShowWrite" @closeWrite="openWrite" @submitWrite="SubmitWrite"></write-comment>
    </div>
</template>

<script>
import CommentTemplate from './CommentTemplate.vue'
import WriteComment from './WriteComment.vue';
export default {
    components: { CommentTemplate, WriteComment },
    data() {
        return {
            ShowWrite: false,
            CommentDataList: [],
            SearchValue: '',
            ClickId: '',
            Limit: 10,
        }
    },
    created() {
        // LIST 호출
        this.Limit = 10;
        this.GetList(0, this.Limit, '');
    },
    mounted() {
        window.addEventListener('scroll', this.windowScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.windowScroll);
    },
    methods: {
        FocusIn() {
            const SearchBox = document.getElementById('search_box');
            SearchBox.classList.add('active');
        },
        FocusOut() {
            const SearchBox = document.getElementById('search_box');
            SearchBox.classList.remove('active');
        },
        openWrite() {
            this.ShowWrite = !this.ShowWrite;
        },
        CloseView() {
            4
            this.ShowView = false;
            this.GetList(0, 10, '');
        },
        OpenView(id) {
            this.$router.push({ name: 'detail', params: { id: id } });
        },
        GoSearch() {
            const Search = document.getElementById('search-input').value;
            if (Search != '') {
                this.SearchValue = Search;
            }
            this.GetList(0, 10, this.SearchValue);
        },
        //리스트 API 호출
        GetList(page, limit, value) {
            const vm = this;
            const getApi = this.$store.dispatch('FETCH_CommentList', {
                page: page,
                limit: limit,
                schValue: value,
            });
            getApi.then(function () {
                vm.CommentDataList = vm.$store.state.CommentList;
            });
        },
        SubmitWrite() {
            this.ShowWrite = false;
            this.GetList(0, 10, '');
        },
        windowScroll() {
            let WindowHeight = window.innerHeight + window.scrollY
            let BodyHeight = document.body.offsetHeight;
            if (WindowHeight + 50 >= BodyHeight) {
                this.Limit = this.Limit + 10;
                this.GetList(0, this.Limit, this.SearchValue);
            }
        }
    }
}
</script>

<style scoped>
.wrap {
    background: #fff;
    padding-top: 60px;
    padding: 60px 5% 20px 5%;
}

.wrap h2 {
    border-top: 1px solid #222;
    padding-top: 30px;
    font-size: 24px;
    font-weight: 700;
}

.search_box {
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #DDD;
    margin: 16px 0 35px 0;
    justify-content: space-between;
}

.search_box.active {
    border: 1px solid #222;
}

.search_box input {
    font-size: 14px;
    padding: 10px 16px;
    background: none;
    flex: 1;
    color: #222;
    outline: none;
}

.search_box input::placeholder {
    color: #888;
}

.search_box button {
    background: none;
    border: none;
    margin-right: 16px;
    cursor: pointer;
}

.comment_list {
    padding-bottom: 50px;
}

.bottom_fixed {
    background: #fff;
    width: 360px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}

@media screen and (max-width:640px) {
    .bottom_fixed {
        width: 100%;
        left: 0;
        transform: translate(0, 0);
    }
}

.write_comment {
    border-radius: 100px;
    background: #FFF;
    box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    cursor: pointer;
}
</style>