<template>
    <div>
        <saju-info></saju-info>
        <comment-list></comment-list>
    </div>
</template>

<script>
import SajuInfo from '@/components/SajuInfo.vue'
import CommentList from '@/components/CommentList.vue'
export default {
    components: { SajuInfo, CommentList },

}
</script>

<style></style>