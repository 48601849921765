<template>
    <div class="wrap">
        <section>
            <h4>내 사주 <span>{{ MySajuList.birthY }}.{{ MySajuList.birthM }}.{{ MySajuList.birthD }}</span></h4>
            <div class="table">
                <div class="title">
                    <div>시</div>
                    <div>일</div>
                    <div>월</div>
                    <div>연</div>
                </div>
                <div class="list">
                    <div><img :src="MySajuList.firstHours" v-show="MySajuList.firstHours" /></div>
                    <div><img :src="MySajuList.firstDay" /></div>
                    <div><img :src="MySajuList.firstMonth" /></div>
                    <div><img :src="MySajuList.firstYear" /></div>
                </div>
                <div class="list">
                    <div><img :src="MySajuList.lastHours" v-show="MySajuList.lastHours" /></div>
                    <div><img :src="MySajuList.lastDay" /></div>
                    <div><img :src="MySajuList.lastMonth" /></div>
                    <div><img :src="MySajuList.lastYear" /></div>
                </div>
            </div>
        </section>
        <div class="icon_plus">
            <img src="@/assets/plus.svg" alt="더하기">
        </div>
        <section>
            <h4>상대방 사주 <span>{{ YourSajuList.birthY }}.{{ YourSajuList.birthM }}.{{ YourSajuList.birthD }}</span></h4>
            <div class="table">
                <div class="title">
                    <div>시</div>
                    <div>일</div>
                    <div>월</div>
                    <div>연</div>
                </div>
                <div class="list">
                    <div><img :src="YourSajuList.firstHours" v-show="YourSajuList.firstHours" /></div>
                    <div><img :src="YourSajuList.firstDay" /></div>
                    <div><img :src="YourSajuList.firstMonth" /></div>
                    <div><img :src="YourSajuList.firstYear" /></div>
                </div>
                <div class="list">
                    <div><img :src="YourSajuList.lastHours" v-show="YourSajuList.lastHours" /></div>
                    <div><img :src="YourSajuList.lastDay" /></div>
                    <div><img :src="YourSajuList.lastMonth" /></div>
                    <div><img :src="YourSajuList.lastYear" /></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {
            MySajuList: this.$store.state.ResultInfo.firstUser,
            YourSajuList: this.$store.state.ResultInfo.lastUser
        }
    },
}
</script>

<style scoped>
.wrap {
    background: #fff;
    padding-top: 40px;
}

.wrap section h4 {
    font-size: 16px;
    text-align: center;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap section h4 span {
    color: #888;
    font-weight: 400;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap section h4 span::before {
    content: '';
    display: block;
    width: 1px;
    height: 12px;
    background: #D9D9D9;
    margin: 0 12px;
}

.wrap section .table {
    width: 90%;
    margin: 8px auto 0 auto;
}

.wrap section .table .title {
    color: #888;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.wrap section .table .title>div {
    flex: 1;
}

.wrap section .table .list {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap section .table .list>div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
}

.wrap section .table .list>div img {
    width: 100%;
    height: 100%;
}

.wrap .icon_plus {
    margin: 20px auto;
    width: 32px;
    height: 32px;
}
</style>