<template>
    <div class="wrap">
        <intro-nav @goto-test="GotoTest" v-show="showPage == 0"></intro-nav>
        <intro-form @gotoIntro="GotoIntro" v-show="showPage == 1" @gotoLoading="GotoLoading"></intro-form>
        <intro-loading v-show="showPage == 2"></intro-loading>
    </div>
</template>

<script>
import IntroNav from '@/components/IntroNav.vue';
import IntroForm from '@/components/IntroForm.vue';
import IntroLoading from '@/components/IntroLoading.vue';
export default {
    data() {
        return {
            showPage: 0
        }
    },
    components: {
        IntroNav,
        IntroForm,
        IntroLoading
    },
    mounted() {
        if (this.$route.query.direct) {
            this.showPage = 1;
        }
    },
    methods: {
        GotoTest() {
            this.showPage = 1;
        },
        GotoIntro() {
            this.showPage = 0;
        },
        GotoLoading() {
            const vm = this;
            this.showPage = 2;

            //데이터 재가공
            const getMyInfo = this.$store.state.MyInfo;
            const getYourInfo = this.$store.state.YourInfo;
            if (getMyInfo.month < 10) {
                getMyInfo.month = '0' + getMyInfo.month;
            }
            if (getMyInfo.date < 10) {
                getMyInfo.date = '0' + getMyInfo.date;
            }
            if (getMyInfo.gender == 'male') {
                getMyInfo.gender = 'M';
            } else {
                getMyInfo.gender = 'W';
            }
            if (getYourInfo.month < 10) {
                getYourInfo.month = '0' + getYourInfo.month;
            }
            if (getYourInfo.date < 10) {
                getYourInfo.date = '0' + getYourInfo.date;
            }
            if (getYourInfo.gender == 'male') {
                getYourInfo.gender = 'M';
            } else {
                getYourInfo.gender = 'W';
            }
            getMyInfo.time = parseInt(getMyInfo.time);
            let MyTime = '모름';
            switch (getMyInfo.time) {
                case 0: MyTime = '모름'; break;
                case 1: MyTime = '자'; break;
                case 2: MyTime = '축'; break;
                case 3: MyTime = '인'; break;
                case 4: MyTime = '묘'; break;
                case 5: MyTime = '진'; break;
                case 6: MyTime = '사'; break;
                case 7: MyTime = '오'; break;
                case 8: MyTime = '미'; break;
                case 9: MyTime = '신'; break;
                case 10: MyTime = '유'; break;
                case 11: MyTime = '술'; break;
                case 12: MyTime = '해'; break;
                case 13: MyTime = '자2'; break;
            }
            getYourInfo.time = parseInt(getYourInfo.time);
            let YourTime = '모름';
            switch (getYourInfo.time) {
                case 0: YourTime = '모름'; break;
                case 1: YourTime = '자'; break;
                case 2: YourTime = '축'; break;
                case 3: YourTime = '인'; break;
                case 4: YourTime = '묘'; break;
                case 5: YourTime = '진'; break;
                case 6: YourTime = '사'; break;
                case 7: YourTime = '오'; break;
                case 8: YourTime = '미'; break;
                case 9: YourTime = '신'; break;
                case 10: YourTime = '유'; break;
                case 11: YourTime = '술'; break;
                case 12: YourTime = '해'; break;
                case 13: YourTime = '자2'; break;
            }
            //API 호출
            const getApi = this.$store.dispatch('FETCH_MatchReunite', {
                firstBirthY: getMyInfo.year,
                firstBirthM: getMyInfo.month,
                firstBirthD: getMyInfo.date,
                firstBirthS: MyTime,
                firstGender: getMyInfo.gender,
                secondBirthY: getYourInfo.year,
                secondBirthM: getYourInfo.month,
                secondBirthD: getYourInfo.date,
                secondBirthS: YourTime,
                secondGender: getYourInfo.gender,
            });
            getApi.then(function () {
                vm.$router.push('/result');
            });
        }
    }
}
</script>

<style scoped></style>