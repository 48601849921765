<template>
    <div class='wrap'>
        <h1>우리.. 다시<br />
            만날 수 있을까?</h1>
        <div class="percent_box">
            <div class="percent_circle">
                <h2>6개월 안에 재회 할 확률</h2>
                <h3><span>{{ RatePercent }}</span>%</h3>
                <div class="doughnut">
                    <Doughnut v-show="SajuInfo" :data="{
                        datasets: [
                            {
                                backgroundColor: ['#1875FF', 'rgb(0,0,0,0)'],
                                data: [loveScore, (100 - loveScore)],
                                borderWidth: 0,
                            }
                        ]
                    }
                        " :options="{
        responsive: false,
        maintainAspectRatio: false,
        cutout: '127',
        radius: '100%',
        animation: {
            duration: 3000,
        },
    }" />
                </div>
            </div>
            <div class="background"></div>
            <button class="btn_saju" v-show="!SajuInfo" @click="gotoSaju">사주 입력하고 가능성 보기</button>
        </div>
        <saju-detail v-if="SajuInfo"></saju-detail>
    </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'vue-chartjs';
import SajuDetail from './SajuDetail.vue';
ChartJS.register(ArcElement, Tooltip);
export default {
    components: { Doughnut, SajuDetail },
    data() {
        return {
            SajuInfo: false,
            RatePercent: '',
            loveScore: this.$store.state.ResultInfo.loveScore,
        }
    },
    created() {
        if (this.$store.state.ResultInfo == '') {
            this.SajuInfo = false;
        } else {
            this.SajuInfo = true;
        }
    },
    mounted() {
        if (this.SajuInfo) {
            let Rate = 0;
            let intervalId;
            const duration = parseInt(3000 / this.loveScore);
            intervalId = setInterval(() => {
                this.RatePercent = Rate;
                Rate++;
                if (Rate > this.loveScore) {
                    clearInterval(intervalId)
                }
            }, duration);
        } else {
            this.RatePercent = '?';
        }
    },
    methods: {
        gotoSaju() {
            this.$router.push({ name: 'intro', query: { direct: true } });
        }
    }
}
</script>

<style scoped>
.wrap h1 {
    font-family: 'iceJaram', sans-serif;
    color: #fff;
    font-size: 40px;
    padding-top: 60px;
    margin: 0 auto;
    text-align: center;
    line-height: normal;
    word-break: keep-all;
}

.wrap .percent_box {
    position: relative;
    width: 100%;
    margin-top: 30px;
    height: 260px;
}

.wrap .percent_box .percent_circle {
    width: 260px;
    height: 260px;
    border-radius: 50%;
    background: #fff;
    border: 3px solid #ddd;
    box-shadow: 0px 20px 28px 0px rgba(0, 71, 255, 0.10);
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    box-sizing: border-box
}

.wrap .percent_box .percent_circle .doughnut {
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -3px;
}

.wrap .percent_box .percent_circle .doughnut canvas {
    width: 260px;
    height: 260px;
}

.wrap .percent_box .percent_circle h2 {
    font-family: 'iceJaram', sans-serif;
    font-size: 20px;
    text-align: center;
    margin-top: 82px;
}

.wrap .percent_box .percent_circle h3 {
    font-size: 40px;
    font-weight: 100;
    text-align: center;
    line-height: normal
}

.wrap .percent_box .percent_circle h3 span {
    font-size: 70px;
    font-weight: 100;
}

.wrap .btn_saju {
    font-size: 16px;
    color: #fff;
    background: #222;
    padding: 16px 10px;
    border-radius: 10px;
    width: 90%;
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 5%;
    cursor: pointer;
}

.wrap .percent_box .background {
    background: #fff;
    width: 100%;
    height: 110px;
    position: absolute;
    bottom: 0;
    left: 0;
}
</style>