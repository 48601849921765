<template>
    <div>
        <view-comment></view-comment>
    </div>
</template>

<script>
import ViewComment from '@/components/ViewComment.vue'
export default {
    components: { ViewComment },

}
</script>

<style></style>