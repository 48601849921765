<template>
    <div class="recomment">
        <div class="user_box">
            <img src="@/assets/recomment.svg" alt="코멘트" class="i_reply" />
            <img src="@/assets/profile.svg" class="thumb" alt="썸네일" />
            <div class="nickname">{{ RecommentData.nickname }}</div>
            <div class="time">{{ RecommentData.created }}</div>
        </div>
        <div class="text">
            {{ RecommentData.contents }}
        </div>
    </div>
</template>

<script>
export default {
    props: ['RecommentData'],
}
</script>

<style scoped>
.recomment .user_box {
    display: flex;
    align-items: center;
    margin: 20px 5% 5px 5%;
}

.recomment .user_box .i_reply {
    margin-bottom: 10px;
    width: 20px;
    height: 20px;
}

.recomment .user_box .thumb {
    width: 24px;
    height: 24px;
}

.recomment .user_box .nickname {
    color: #888;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    margin: 0 4px;
}

.recomment .user_box .time {
    color: #888;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
}

.recomment .text {
    color: #222;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 5% 0 68px;
}
</style>