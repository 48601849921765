import axios from 'axios';

const Domain = 'https://moim-api.chooldo.com';

//재회사주보기
function fetchMatchReunite(
    firstBirthY,
    firstBirthM,
    firstBirthD,
    firstBirthS,
    firstGender,
    secondBirthY,
    secondBirthM,
    secondBirthD,
    secondBirthS,
    secondGender
) {
    return axios.post(Domain + '/api/user/tetralogy', null, {
        params: {
            firstBirthY: firstBirthY,
            firstBirthM: firstBirthM,
            firstBirthD: firstBirthD,
            firstBirthS: firstBirthS,
            firstGender: firstGender,
            secondBirthY: secondBirthY,
            secondBirthM: secondBirthM,
            secondBirthD: secondBirthD,
            secondBirthS: secondBirthS,
            secondGender: secondGender,
        },
    });
}

//리스트 불러오기
function fetchCommentList(page, limit, schValue) {
    return axios.get(Domain + '/api/board/poll/list', {
        params: {
            page: page,
            limit: limit,
            schValue: schValue,
        },
    });
}

//게시물 등록
function fetchCommentWrite(nickname, pollContents) {
    return axios.post(Domain + '/api/board/poll/write', {
        nickname: nickname,
        pollContents: pollContents,
    });
}

//게시물 투표
function fetchCommentVote(seq, answer) {
    return axios.post(Domain + '/api/board/poll/select', {
        seq: seq,
        answer: answer,
    });
}
//게시물 상세보기
function fetchCommentView(id) {
    return axios.get(Domain + '/api/board/poll/view/' + id);
}
//게시물 댓글보기
function fetchRecommentList(page, limit, seq) {
    return axios.get(Domain + '/api/board/poll/reply/list', {
        params: {
            page: page,
            limit: limit,
            boardSeq: seq,
        },
    });
}
//게시물 댓글쓰기
function fetchRecommentWrite(seq, nickname, content) {
    return axios.post(Domain + '/api/board/poll/reply/write', {
        seq: seq,
        nickname: nickname,
        replyContents: content,
    });
}

export {
    fetchMatchReunite,
    fetchCommentList,
    fetchCommentWrite,
    fetchCommentVote,
    fetchCommentView,
    fetchRecommentList,
    fetchRecommentWrite,
};
