<template>
    <div class="wrap-viewcomment">
        <div class="content">
            <div class="user_box">
                <img src="@/assets/profile.svg" class="thumb" alt="썸네일" />
                <div class="nickname">{{ Data.nickname }}</div>
                <div class="time">{{ CreatedTime }}</div>
            </div>
            <div class="text_box">
                <p v-html="ContentText">
                </p>
            </div>
            <div class="vote_box">
                <div class="agree vote">
                    <div class="percent" :style="{ width: Data.answer1Percent + '%' }"></div>
                    <div class="text"><span class="txt">재회할 수 있을 것 같다</span><span>{{ Data.answer1Percent }}%
                            ({{ $filters.formatNumber(Data.answer1Count) }}표)</span></div>
                </div>
                <div class="disagree vote">
                    <div class="percent" :style="{ width: Data.answer2Percent + '%' }"></div>
                    <div class="text"><span class="txt">재회하기 힘들 것 같다</span><span>{{ Data.answer2Percent }}% ({{
                        $filters.formatNumber(Data.answer2Count) }}표)</span></div>
                </div>
            </div>
        </div>
        <div class="list_recomment">
            <recomment-template v-for=" item  in  CommentList " :key="item" :RecommentData="item"></recomment-template>
        </div>
        <div class="empty_box" v-show="empty">
            답글이 없어요
        </div>
        <div class="insert_recomment">
            <div class="insert_nickname" v-show="FocusInsert">
                <img src="@/assets/recomment.svg" alt="코멘트" />
                <input type="text" id="write-nickname" placeholder="닉네임 입력" maxlength="8">
            </div>
            <div class="insert">
                <img src="@/assets/recomment.svg" alt="코멘트" v-show="!FocusInsert" />
                <input type="text" id="write-recomment" placeholder="답글 입력" @focus="FocusOn($event)"
                    @keyup="TypeInsert($event)">
                <button type="button" v-show="TypeSubmit" @click="RecommentSubmit">등록</button>
            </div>
        </div>
    </div>
</template>

<script>
import RecommentTemplate from './RecommentTemplate.vue';
import { fetchCommentView, fetchRecommentWrite, fetchRecommentList } from '@/api/api';
export default {
    components: { RecommentTemplate },
    data() {
        return {
            Data: [],
            CommentList: [],
            FocusInsert: false,
            TypeSubmit: false,
            ContentText: '',
            CreatedTime: '',
            Limit: 10,
            empty: true,
        }
    },
    created() {
        const vm = this;
        vm.getAPI(this.$route.params.id).then(() => {
            vm.getRecomment(vm.Limit);
        })
    },
    mounted() {
        window.addEventListener('scroll', this.windowScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.windowScroll);
    },
    methods: {
        FocusOn(event) {
            this.FocusInsert = true;
            event.currentTarget.classList.add('focus');
        },
        TypeInsert(event) {
            if (event.currentTarget.value != '') {
                this.TypeSubmit = true;
            } else {
                this.TypeSubmit = false;
            }
        },

        //상세보기 API 호출
        async getAPI(id) {
            const vm = this;
            await fetchCommentView(id).then((res) => {
                if (res.data.code == 'S0000') {
                    vm.Data = res.data.data.boardPoll;
                    vm.ContentText = vm.Data.contents.replace(/\n/g, "<br>");
                    if (vm.Data.created.length > 12) {
                        vm.CreatedTime = vm.Data.created.slice(0, 10);
                    } else {
                        vm.CreatedTime = vm.Data.created;
                    }
                }
            }).catch((error) => console.log(error));
        },

        //댓글달기
        async RecommentSubmit() {
            const vm = this;
            const WrNickname = document.getElementById('write-nickname');
            const WrContext = document.getElementById('write-recomment')
            const WrNicknameVal = WrNickname.value;
            const WrContextVal = WrContext.value;
            if (!WrNicknameVal) {
                WrNickname.focus();
                return false;
            }
            await fetchRecommentWrite(vm.Data.seq, WrNicknameVal, WrContextVal).then((res) => {
                if (res.data.code == 'S0000') {
                    location.reload();
                }
            }).catch((error) => console.log(error));
        },
        //댓글리스트 호출
        async getRecomment(limit) {
            const vm = this;
            await fetchRecommentList(0, limit, vm.Data.seq).then((res) => {
                if (res.data.code == 'S0000') {
                    vm.CommentList = res.data.data.res;
                    if (vm.CommentList[0]) {
                        vm.empty = false;
                    }
                }
            }).catch((error) => console.log(error));
        },
        windowScroll() {
            let WindowHeight = window.innerHeight + window.scrollY
            let BodyHeight = document.body.offsetHeight;
            if (WindowHeight + 50 >= BodyHeight) {
                this.Limit = this.Limit + 10;
                this.getRecomment(this.Limit);
            }
        }
    }
}
</script>

<style scoped>
.wrap-viewcomment {
    background: #fff;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.wrap-viewcomment .content-wrap .content {
    padding-bottom: 20px;
}

.wrap-viewcomment .list_recomment {
    flex: 1;
    padding-bottom: 80px;
}

.wrap-viewcomment .list_recomment::-webkit-scrollbar {
    display: none;
}

.wrap-viewcomment .insert_recomment {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 360px;
    background: #fff;
}

.wrap-viewcomment .insert_recomment .insert {
    margin: 0 5%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
}

.wrap-viewcomment .insert_recomment .insert input {
    padding: 10px 50px 10px 16px;
    border-radius: 10px;
    background: #F8F8F8;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    flex: 1;
}

.wrap-viewcomment .insert_recomment .insert input.focus {
    margin-left: 20px;
}

.wrap-viewcomment .insert_recomment .insert input::placeholder {
    color: #AAA;
}

.wrap-viewcomment .insert_recomment .insert input:focus {
    outline: none;
}

.wrap-viewcomment .insert_recomment .insert button {
    color: #00069C;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    background: none;
    cursor: pointer;
}


.wrap-viewcomment .insert_recomment .insert_nickname {
    margin: 0 5%;
    padding-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
}

.wrap-viewcomment .insert_recomment .insert_nickname input {
    padding: 10px 16px;
    border-radius: 10px;
    background: #F8F8F8;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 30%;
}

.wrap-viewcomment .insert_recomment .insert_nickname input::placeholder {
    color: #AAA;
}

.wrap-viewcomment .insert_recomment .insert_nickname input:focus {
    outline: none;
}

.wrap-viewcomment .empty_box {
    color: #AAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:640px) {
    .wrap-viewcomment .insert_recomment {
        left: 0;
        width: 100%;
        transform: translate(0, 0);
    }
}

.user_box {
    display: flex;
    align-items: center;
    padding: 20px 5% 5px 5%;
}

.user_box .thumb {
    width: 24px;
    height: 24px;
}

.user_box .nickname {
    color: #888;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    margin: 0 4px;
}

.user_box .time {
    color: #888;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
}

.text_box {
    margin: 0 20px 0 45px;
    position: relative;
}

.text_box p {
    width: 100%;
    line-height: 1.4;
    font-size: 16px;
    word-break: break-all;
}

.vote_box {
    margin: 10px 20px 0 45px;
}

.vote_box>div {
    border-radius: 8px;
    background: #F8F8F8;
    position: relative;
    width: 100%;
    height: 40px;
    margin-bottom: 4px;
    cursor: pointer;
}

.vote_box>div.active {
    border: 1px solid #00069C;
}

.vote_box .percent {
    height: 40px;
    border-radius: 8px;
}

.vote_box .agree .percent {
    background: #CCDCFF;
}

.vote_box .disagree .percent {
    background: #DDD;
}

.vote_box .text {
    position: absolute;
    top: 0px;
    left: 5%;
    line-height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 90%;
}

.vote_box .text span {
    display: block;
}

.vote_box>div.active .text .txt {
    display: flex;
    align-items: center;
}
</style>