<template>
    <div class="wrap">
        <h1>6개월 안에 재회 할 수 있을지
            사주를 분석해 알려드려요</h1>
        <form id="intro-form">
            <fieldset class="me" v-if="Me">
                <legend>내 생년월일시</legend>
                <div class="birth">
                    <select name="my_year">
                        <option v-for="year in thisYear" :key="year">{{ year }}</option>
                    </select>
                    <select name="my_month">
                        <option v-for="month in 12" :key="month">{{ month }}</option>
                    </select>
                    <select name="my_date">
                        <option v-for="date in 31" :key="date">{{ date }}</option>
                    </select>
                </div>
                <div class="time">
                    <select name="my_time">
                        <option v-for="(time, idx) in TraditionalTime" :key="idx" :data-idx="idx">{{ time }}</option>
                    </select>
                </div>
                <div class="gender">
                    <div>
                        <input type="radio" id="female" name="my_gender" checked value="female">
                        <label for="female"><span></span>여자</label>
                    </div>
                    <div>
                        <input type="radio" id="male" name="my_gender" value="male">
                        <label for="male"><span></span>남자</label>
                    </div>
                </div>
            </fieldset>
            <fieldset class="you" v-else>
                <legend>상대방 생년월일시</legend>
                <div class="birth">
                    <select name="your_year">
                        <option v-for="year in thisYear" :key="year">{{ year }}</option>
                    </select>
                    <select name="your_month">
                        <option v-for="month in 12" :key="month">{{ month }}</option>
                    </select>
                    <select name="your_date">
                        <option v-for="date in 31" :key="date">{{ date }}</option>
                    </select>
                </div>
                <div class="time">
                    <select name="your_time">
                        <option v-for="(time, idx) in TraditionalTime" :key="idx" :data-idx="idx">{{ time }}</option>
                    </select>
                </div>
                <div class="gender">
                    <div>
                        <input type="radio" id="female" name="your_gender" value="female">
                        <label for="female"><span></span>여자</label>
                    </div>
                    <div>
                        <input type="radio" id="male" name="your_gender" checked value="male">
                        <label for="male"><span></span>남자</label>
                    </div>
                </div>
            </fieldset>
        </form>
        <div class='btns'>
            <button class="prev" @click="Prev($event)">이전</button>
            <button class="next" @click="Next($event)">다음</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Me: true,
            TraditionalTime: ['시간 모름', '자시(00:31~01:30)', '축시(01:31~03:30)', '인시(03:31~05:30)', '묘시(05:31~07:30)', '진시(07:31~09:30)', '사시(09:31~11:30)', '오시(11:31~13:30)', '미시(13:31~15:30)', '신시(15:31~17:30)', '유시(17:31~19:30)', '술시(19:31~21:30)', '해시(21:31~23:30)', '자시(23:31~00:30)'],
            MyInfo: {},
            YourInfo: {},
        }
    },
    computed: {
        thisYear() {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            let Years = [];
            for (let i = currentYear; i >= 1900; i--) {
                Years.push(i);
            }
            return Years;
        }
    },
    methods: {
        Prev(event) {
            if (this.Me) {
                this.$emit('gotoIntro');
            } else {
                this.Me = true;
                event.currentTarget.nextSibling.innerText = '다음';
            }
        },
        Next(event) {
            const Form = document.getElementById('intro-form');
            if (this.Me) {
                this.Me = false;
                event.currentTarget.innerText = '완료';
                const MyTime = Form.my_time.options[Form.my_time.selectedIndex].getAttribute('data-idx');
                this.MyInfo = {
                    year: parseInt(Form.my_year.value),
                    month: parseInt(Form.my_month.value),
                    date: parseInt(Form.my_date.value),
                    time: MyTime,
                    gender: Form.my_gender.value
                }
            } else {
                const YourTime = Form.your_time.options[Form.your_time.selectedIndex].getAttribute('data-idx')
                this.YourInfo = {
                    year: parseInt(Form.your_year.value),
                    month: parseInt(Form.your_month.value),
                    date: parseInt(Form.your_date.value),
                    time: YourTime,
                    gender: Form.your_gender.value
                }
                this.$store.commit('SET_MyInfo', this.MyInfo);
                this.$store.commit('SET_YourInfo', this.YourInfo);
                this.$emit('gotoLoading');
            }


        },
    }
}
</script>

<style scoped>
.wrap h1 {
    font-family: 'iceJaram', sans-serif;
    color: #fff;
    font-size: 24px;
    width: 70%;
    padding-top: 100px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.3;
    word-break: keep-all;
}

.wrap form {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 2px 2px 12px 0px rgba(0, 0, 0, 0.20);
    margin: 30px 5% 20px 5%;
    padding: 20px;
}

.wrap form legend {
    padding-top: 10px;
    font-family: 'iceJaram', sans-serif;
    font-size: 28px;
    text-align: center;
    margin-bottom: 16px;
}

.wrap form fieldset>div {
    display: flex;
    align-items: center;
}

.wrap form fieldset>div select {
    flex: 1;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px 15px;
    text-align: center;
    font-size: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../assets//select_arrow.svg) no-repeat 97% 50%/12px auto #F8F8F8;
}

.wrap form fieldset>div select::-ms-expand {
    display: none
}

.wrap form fieldset>div select:nth-child(2) {
    margin: 0 7px;
}

.wrap form fieldset>div.time {
    margin: 10px 0 20px 0;
}

.wrap form fieldset .gender {
    justify-content: center
}

.wrap form fieldset .gender>div {
    margin: 0 10%;
}

.wrap form fieldset .gender input[type='radio'] {
    display: none;
}

.wrap form fieldset .gender input[type='radio']+label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
}

.wrap form fieldset .gender input[type='radio']:checked+label {
    color: #2666EC;
    font-weight: 700;
}

.wrap form fieldset .gender input[type='radio']+label span {
    width: 20px;
    height: 20px;
    background: url(../assets/checkbox.svg) no-repeat;
    display: block;
    margin-right: 5px;
}

.wrap form fieldset .gender input[type='radio']:checked+label span {
    background: url(../assets//checkbox_check.svg) no-repeat;
}

.btns {
    margin: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btns button {
    color: #fff;
    text-align: center;
    background: #222;
    font-size: 16px;
    font-weight: 700;
    padding: 10px 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    line-height: 24px;
    cursor: pointer;
}

.btns button.prev::before {
    content: '';
    display: block;
    width: 12px;
    height: 8px;
    background: url(../assets//select_arrow.svg) no-repeat;
    transform: rotate(90deg);
    margin-right: 10px;
}

.btns button.next::after {
    content: '';
    display: block;
    width: 12px;
    height: 8px;
    background: url(../assets//select_arrow.svg) no-repeat;
    transform: rotate(-90deg);
    margin-left: 10px;
}
</style>