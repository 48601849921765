import { createRouter, createWebHistory } from 'vue-router';
import IntroView from '@/views/IntroView.vue';
import ResultView from '@/views/ResultView.vue';
import DetailView from '@/views/DetailView.vue';

const routes = [
    {
        path: '/',
        name: 'intro',
        component: IntroView,
    },
    {
        path: '/result',
        name: 'result',
        component: ResultView,
    },
    {
        path: '/detail/:id',
        name: 'detail',
        component: DetailView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return {
            top: 0,
        };
    },
});

export default router;
