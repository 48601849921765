<template>
    <form class="wrap-writecomment" @submit.prevent>
        <div class="close-bg" @click="CloseWrite"></div>
        <div class="write-form">
            <div>
                <label for="write-]nickname">닉네임</label>
                <input type="text" id="write-nickname" placeholder="닉네임 입력" maxlength="8">
            </div>
            <div>
                <label for="write-text">사연 남기기</label>
                <textarea id="write-text" placeholder="사연을 적어보세요"></textarea>
            </div>
            <button type="button" class="btn_submit" @click="WriteSubmit">등록</button>
        </div>
    </form>
</template>

<script>
export default {
    methods: {
        CloseWrite() {
            this.$emit('closeWrite');
        },
        WriteSubmit() {
            //글 등록 API 호출
            const vm = this;
            const NickName = document.getElementById('write-nickname');
            const Context = document.getElementById('write-text');
            const NickNameVal = NickName.value;
            const ContextVal = Context.value;
            console.log(ContextVal);
            if (!NickNameVal || NickNameVal == '') {
                NickName.focus();
                return false;
            } else if (!ContextVal || ContextVal == '') {
                Context.focus();
                return false;
            } else{
                const getApi = this.$store.dispatch('FETCH_CommentWrite', {
                nickname: NickNameVal,
                pollContents: ContextVal
                });
                getApi.then(function () {
                    vm.$emit('submitWrite');
                });
            }

        }
    }
}
</script>

<style scoped>
.wrap-writecomment {
    background: rgba(0, 0, 0, 0.70);
    width: 360px;
    height: 100vh;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    animation: Opacity .5s forwards;
}

.wrap-writecomment .close-bg {
    width: 100%;
    height: 100%;
}

.wrap-writecomment .write-form {
    border-radius: 8px 8px 0px 0px;
    background: #FFF;
    width: 360px;
    min-height: 324px;
    position: absolute;
    bottom: -100%;
    left: 0;
    animation: Appear 1s forwards;
    z-index: 11;
}

.wrap-writecomment .write-form>div {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-top: 16px;
}

.wrap-writecomment .write-form input[type=text] {
    border-radius: 10px;
    background: #F8F8F8;
    font-size: 14px;
    line-height: 20px;
    width: 50%;
    margin-right: auto;
    flex: 1;
    padding: 10px 16px;
    color: #222;
}

.wrap-writecomment .write-form input[type=text]::placeholder {
    color: #AAA;
}

.wrap-writecomment .write-form input[type=text]:focus {
    border: 1px solid #222;
    outline: none;
}

.wrap-writecomment .write-form textarea {
    border-radius: 10px;
    background: #F8F8F8;
    border: none;
    resize: none;
    padding: 10px 16px;
    flex: 1;
    min-height: 120px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #222;
}

.wrap-writecomment .write-form textarea::placeholder {
    color: #AAA;
}

.wrap-writecomment .write-form textarea:focus {
    border: 1px solid #222;
    outline: none;
}

.wrap-writecomment .write-form label {
    color: #222;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 8px;
}

.wrap-writecomment .btn_submit {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    border-radius: 10px;
    background: #222;
    display: flex;
    width: 90%;
    margin: 20px 5% 12px 5%;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

@keyframes Opacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes Appear {
    from {
        bottom: -100%;
    }

    to {
        bottom: 0;
    }
}

@media screen and (max-width:640px) {
    .wrap-writecomment {
        width: 100%;
    }

    .wrap-writecomment .write-form {
        width: 100%;
    }
}
</style>