<template>
    <div class="comment_wrap" :id="'comment' + CommentData.seq">
        <div class="user_box">
            <img src="@/assets/profile.svg" class="thumb" alt="썸네일" />
            <div class="nickname">{{ CommentData.nickname }}</div>
            <div class="time">{{ forMatDate }}</div>
        </div>
        <div class="text_box" @click="BtnShowView">
            <p v-html="ConTentText">
            </p>
            <div class="filter" v-show="showFilter"></div>
        </div>
        <div class="vote_box" id="vote-box">
            <div class="agree vote" @click="SelectVote($event, CommentData.seq)">
                <div class="percent" :style="{ width: CommentData.answer1Percent + '%' }"></div>
                <div class="text"><span class="txt">재회할 수 있을 것 같다</span><span>{{ CommentData.answer1Percent }}%
                        ({{ $filters.formatNumber(CommentData.answer1Count) }}표)</span></div>
            </div>
            <div class="disagree vote" @click="SelectVote($event, CommentData.seq)">
                <div class="percent" :style="{ width: CommentData.answer2Percent + '%' }"></div>
                <div class="text"><span class="txt">재회하기 힘들 것 같다</span><span>{{ CommentData.answer2Percent }}% ({{
                    $filters.formatNumber(CommentData.answer2Count) }}표)</span></div>
            </div>
        </div>
        <div class="btns_box">
            <button v-show="CommentData.replyCount > 0" @click="BtnShowView">
                <img src="@/assets/recomment.svg" alt="답글보기" />
                답글 보기 (+{{ $filters.formatNumber(CommentData.replyCount) }})
            </button>
            <div class="btn_right">
                <button class="btn_vote" id="btn-vote" @click="BtnVote($event, CommentData.seq)">투표하기</button>
                <button class="btn_recomment" @click="BtnShowView">답글달기</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showFilter: false,
        }
    },
    props: ['CommentData'],
    mounted() {
        const ContentBox = document.getElementById('comment' + this.CommentData.seq).querySelector('p');
        if (ContentBox.offsetHeight > 80) {
            this.showFilter = true
        }
    },
    computed: {
        ConTentText() {
            const Content = this.CommentData.contents.replace(/\n/g, "<br>");
            return Content;
        },
        forMatDate() {
            let Time;
            if (this.CommentData.created.length > 12) {
                Time = this.CommentData.created.slice(0, 10);
            } else {
                Time = this.CommentData.created;
            }
            return Time;
        }
    },
    methods: {
        SelectVote(event, Idx) {
            if (!this.$store.state.VotedList.includes(Idx)) {
                //항목선택
                const Target = document.getElementById('comment' + Idx);
                const VoteBox = Target.querySelector('#vote-box');
                const Votes = VoteBox.querySelectorAll('.vote');
                for (var i = 0; i < Votes.length; i++) {
                    Votes[i].classList.remove('active');
                }
                event.currentTarget.classList.add('active');
                const BtnVote = Target.querySelector('#btn-vote');
                BtnVote.classList.add('active');
            }
        },
        BtnVote(event, idx) {
            if (event.currentTarget.classList.contains('active')) {
                const VoteWrap = document.getElementById('comment' + idx);
                const Vote = VoteWrap.querySelector('.vote.active');
                const vm = this;
                let Answer = '';
                if (Vote.classList.contains('agree')) {
                    Answer = 'answer1';
                } else {
                    Answer = 'answer2';
                }
                //투표하기 API 호출
                const getApi = this.$store.dispatch('FETCH_CommentVote', {
                    seq: idx,
                    answer: Answer
                });
                getApi.then(function () {
                    vm.$emit('getlistAgain');

                    if (!vm.$store.state.VotedList.includes(idx)) {
                        vm.$store.commit('SET_VotedList', idx);
                    }
                });
            }
        },
        BtnShowView() {
            this.$emit('BtnShowView', this.CommentData.id);
        }
    }
}
</script>

<style scoped>
.comment_wrap {
    border-bottom: 1px solid #EEE;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.user_box {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.user_box .thumb {
    width: 24px;
    height: 24px;
}

.user_box .nickname {
    color: #888;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    margin: 0 4px;
}

.user_box .time {
    color: #888;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
}

.text_box {
    margin-left: 28px;
    position: relative;
}

.text_box p {
    width: 100%;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 16px;
    cursor: pointer;
    word-break: break-all;
}

.text_box .filter {
    width: 292px;
    height: 60px;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.90) 100%);

}

.vote_box {
    margin: 10px 0 0 28px;
}

.vote_box>div {
    border-radius: 8px;
    background: #F8F8F8;
    position: relative;
    width: 100%;
    height: 40px;
    margin-bottom: 4px;
    cursor: pointer;
}

.vote_box>div.active {
    border: 1px solid #00069C;
}

.vote_box .percent {
    height: 40px;
    border-radius: 8px;
}

.vote_box .agree .percent {
    background: #CCDCFF;
}

.vote_box .disagree .percent {
    background: #DDD;
}

.vote_box .text {
    position: absolute;
    top: 0px;
    left: 5%;
    line-height: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 90%;
}

.vote_box .text span {
    display: block;
}

.btns_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 28px;
    margin-top: 15px;
}

.vote_box>div.active .text .txt {
    display: flex;
    align-items: center;
}

.vote_box>div.active .text .txt::before {
    content: '';
    width: 16px;
    height: 16px;
    background: url(../assets/i_vote.svg) no-repeat;
    background-size: 100%;
    display: block;
    margin-right: 5px;
}

.btns_box button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.btns_box .btn_right {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.btns_box button.btn_vote {
    margin-right: 20px;
    color: #AAA;
}

.btns_box button.btn_vote.active {
    color: #222;
}

.btns_box button.btn_recomment {}
</style>