import { createStore } from 'vuex';
import {
    fetchMatchReunite,
    fetchCommentList,
    fetchCommentWrite,
    fetchCommentVote,
} from '@/api/api.js';

import createPersistedState from 'vuex-persistedstate';

const store = createStore({
    state: {
        MyInfo: {},
        YourInfo: {},
        ResultInfo: '',
        CommentList: {},
        VotedList: [],
    },
    getters: {},
    mutations: {
        SET_MyInfo(state, MyInfo) {
            state.MyInfo = MyInfo;
        },
        SET_YourInfo(state, YourInfo) {
            state.YourInfo = YourInfo;
        },
        SET_ResultInfoReset(state) {
            state.ResultInfo = '';
        },
        SET_ResultInfo(state, ResultInfo) {
            state.ResultInfo = ResultInfo;
        },
        SET_CommentList(state, data) {
            state.CommentList = data;
        },
        SET_VotedList(state, data) {
            state.VotedList.push(data);
        },
    },
    actions: {
        //재회사주보기
        async FETCH_MatchReunite(
            res,
            {
                firstBirthY,
                firstBirthM,
                firstBirthD,
                firstBirthS,
                firstGender,
                secondBirthY,
                secondBirthM,
                secondBirthD,
                secondBirthS,
                secondGender,
            }
        ) {
            await fetchMatchReunite(
                firstBirthY,
                firstBirthM,
                firstBirthD,
                firstBirthS,
                firstGender,
                secondBirthY,
                secondBirthM,
                secondBirthD,
                secondBirthS,
                secondGender
            )
                .then(function (response) {
                    res.commit('SET_ResultInfo', response.data.data.data);
                })
                .catch(error => console.log(error));
        },
        //리스트 불러오기
        async FETCH_CommentList(res, { page, limit, schValue }) {
            await fetchCommentList(page, limit, schValue)
                .then(function (response) {
                    res.commit('SET_CommentList', response.data.data.res);
                })
                .catch(error => console.log(error));
        },
        //게시물 등록
        async FETCH_CommentWrite(res, { nickname, pollContents }) {
            await fetchCommentWrite(nickname, pollContents).catch(error =>
                console.log(error)
            );
        },
        //게시물 투표
        async FETCH_CommentVote(res, { seq, answer }) {
            await fetchCommentVote(seq, answer).catch(error =>
                console.log(error)
            );
        },
    },
    plugins: [createPersistedState()],
});
export default store;
