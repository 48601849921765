<template>
    <div class="wrap">
        <h1>우리.. 다시<br />
            만날 수 있을까?</h1>
        <h2>6개월 안에 재회 할 수 있을지
            사주를 분석해 알려드려요</h2>
        <button class="btn_black" @click="gotoTest">재회 가능성 보기</button>
        <button class="btn_white" @click="gotoComment">재회 댓글 보러 가기</button>
    </div>
</template>

<script>
export default {
    methods: {
        gotoTest() {
            this.$emit('goto-test')
        },
        gotoComment() {
            this.$store.commit('SET_ResultInfoReset');
            this.$router.push('/result');
        }
    }
}
</script>

<style scoped>
.wrap h1 {
    font-family: 'iceJaram', sans-serif;
    color: #fff;
    font-size: 52px;
    font-weight: 400;
    text-align: center;
    padding-top: 160px;
}

.wrap h2 {
    font-family: 'iceJaram', sans-serif;
    color: #fff;
    font-size: 24px;
    width: 70%;
    margin: 48px auto 15px auto;
    text-align: center;
    line-height: 1.3;
    word-break: keep-all;
}

.wrap button {
    padding: 16px 10px;
    margin: 0 5% 13px 5%;
    width: 90%;
    border-radius: 10px;
    cursor: pointer;
}

.wrap .btn_black {
    color: #fff;
    font-weight: 700;
    background: #222;
}

.wrap .btn_white {
    background: rgba(255, 255, 255, 0.40);
    border: 1px solid #fff;
    color: #00069C;
    font-weight: 400;
}
</style>