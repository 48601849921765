<template>
	<div class="body_wrap">
		<div class="device_wrap">
			<router-view />
		</div>
	</div>
</template>

<style>
@import url(../src/css/reset.css);

.body_wrap {
	background-color: #f8f8f8;
	height: 100%;
	width: 100%;
	min-height: 100vh;
}

.body_wrap .device_wrap {
	width: 360px;
	height: 100%;
	min-height: 100vh;
	background: #fff;
	margin: 0 auto;
	background: url(assets/intro-bg.png) no-repeat;
	background-size: cover;
}

@media screen and (max-width:640px) {
	.body_wrap .device_wrap {
		width: 100%;
	}
}
</style>
